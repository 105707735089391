import React, { useEffect, useRef, useState } from 'react'
import styles from './LineGraph.module.scss'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  type TooltipModel
} from 'chart.js'
import 'chartjs-adapter-luxon'
import { Line } from 'react-chartjs-2'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import { renderToStaticMarkup } from 'react-dom/server'
import clsx from 'clsx'
import { uniqWith } from 'lodash'

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  AnnotationPlugin
)

type Props = {
  type?: 'full' | 'compact'
  className?: string
  labels: (string | null)[]
  datasets: IDataset[]
  tooltipComponent?: any
  height?: number | string
  chartOptions?: any
  annotation?: any
}

export type ITooltip = {
  label: string
  opacity: number
  x: number
  y: number
  items: {
    label: string
    value: number
  }[]
  chart: ChartJS
  valueFormatter: (_: number) => string
}

export type IDataset = {
  label: string
  color: string
  data: (number | null)[]
  borderStyle: 'dashed' | 'solid'
  stepped?: boolean | 'after' | 'before' | 'middle'
  pointStyle?: any
  pointRadius?: number
  pointBorderColor?: string
  segment?: Record<string, any>
}

const dash = [6, 5]

const stylingBase = {
  fill: true,
  borderWidth: 3,
  pointHitRadius: 30,
  pointRadius: 3,
  pointBackgroundColor: 'transparent',
  pointHoverRadius: 8,
  pointHoverBorderWidth: 3,
  pointHoverBackgroundColor: 'white'
}

export default function LineGraph(props: Props) {
  const animating = true
  const [data, setData] = useState({
    labels: props.labels,
    datasets: props.datasets.map((d) => ({
      ...stylingBase,
      borderDash: d.segment ? undefined : d.borderStyle === 'dashed' ? dash : [],
      borderColor: d.color,
      stepped: d.stepped,
      pointHoverBorderColor: d.color,
      backgroundColor: d.color.replace(')', ', 0.7'),
      data: d.data,
      label: d.label,
      pointBorderColor: [props.type !== 'compact' ? d.color : 'transparent'],
      pointStyle: d.pointStyle,
      pointRadius: d.pointRadius,
      segment: d.segment
    }))
  })

  const tooltipRenderer = ({
    tooltip,
    chart
  }: {
    tooltip: TooltipModel<'line'>
    chart: ChartJS
  }) => {
    const tooltipContainer = document.getElementById('tooltip')
    if (!tooltip.dataPoints || !tooltipContainer) {
      return
    }

    const { dataIndex, label } = tooltip.dataPoints[0]
    const { opacity, caretX: x, caretY: y } = tooltip
    const items = chart.data.datasets.map((d) => ({
      label: d.label || '',
      value: d.data[dataIndex] as number
    }))
    const uniqueItems = uniqWith(items, (a, b) => a.label === b.label && a.value === b.value)

    const valueFormatter =
      props?.chartOptions?.scales?.y?.ticks?.callback ?? ((x: number) => '' + x)

    const tooltipProps: ITooltip = {
      x,
      y,
      label,
      items: uniqueItems,
      opacity,
      chart,
      valueFormatter
    }
    tooltipContainer.innerHTML = renderToStaticMarkup(<props.tooltipComponent {...tooltipProps} />)
  }

  const chartRef = useRef(null) as any

  const createLinearGradient = (color: string) => {
    const ctx = chartRef.current?.ctx as any
    if (!ctx || typeof color !== 'string') {
      return color
    }

    const gradientLine = ctx.createLinearGradient(0, 0, 0, chartRef.current.height)

    gradientLine.addColorStop(0, color)
    gradientLine.addColorStop(1, 'rgb(255, 255, 255, 0)')

    return gradientLine
  }

  const updateBackgrounds = () => {
    if (!animating) {
      return
    }

    const datasets = data?.datasets.map((d) => ({
      ...d,
      backgroundColor: createLinearGradient(d.backgroundColor)
    }))

    setData({ ...data, datasets })
    chartRef.current.update()
  }

  useEffect(() => {
    updateBackgrounds()
  }, [])

  const options = {
    ...props.chartOptions,
    ...(props.type === 'compact' ? { hover: { mode: null } } : {}),
    responsive: true,
    layout: {
      padding: 0
    },
    scales: {
      y: {
        ...props.chartOptions?.scales?.y,
        grid: {
          borderDash: dash,
          display: props.type !== 'compact',
          drawBorder: false
        },
        ticks: {
          ...props.chartOptions?.scales?.y?.ticks,
          padding: 30,
          display: props.type !== 'compact',
          font: {
            family: "'Inter'"
          }
        }
      },
      x: {
        ...props.chartOptions?.scales?.x,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          ...props.chartOptions?.scales?.x?.ticks,
          padding: 5,
          font: {
            family: "'Inter'"
          },
          display: props.type !== 'compact'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      filler: {
        propagate: true
      },
      tooltip: {
        enabled: false,
        external: props.type !== 'compact' ? tooltipRenderer : undefined
      },
      annotation: { annotations: props.annotation }
    },
    animation: {
      onProgress: () => {
        // if (!animating) {
        //   console.log('onProgress')
        //   setAnimating(true)
        // } else return
      },
      onComplete: () => {
        // console.log('onComplete')
        // setAnimating(false)
      }
    }
  }

  useEffect(() => {
    const newData = {
      labels: props.labels,
      datasets: props.datasets.map((d) => ({
        ...stylingBase,
        borderDash: d.segment ? undefined : d.borderStyle === 'dashed' ? dash : [],
        borderColor: d.color,
        stepped: d.stepped,
        pointHoverBorderColor: d.color,
        backgroundColor: createLinearGradient(d.color.replace(')', ', 0.7')),
        data: d.data,
        label: d.label,
        pointBorderColor: [props.type !== 'compact' ? d.color : 'transparent'],
        pointStyle: d.pointStyle,
        pointRadius: d.pointRadius,
        segment: d.segment
      }))
    }
    setData(newData)
    chartRef.current.data = newData
    chartRef.current.update()
  }, [props.datasets])

  return (
    <div className={clsx(styles.graph, props.className, 'position-relative')}>
      <Line
        className="skeleton-hide"
        height={props.height ?? 'auto'}
        ref={chartRef}
        options={options}
        data={data}
      />
      <div className="skeleton-loader" />
      <div id="tooltip" />
    </div>
  )
}
