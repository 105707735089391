import { type AwardValuation, type ValuationType } from '~/models/EsopSchema'
import { useCompanyStore } from '~/stores/company'
import { findLast, flatten, uniq } from 'lodash'
import { DateTime } from 'ts-luxon'
import { type IDataset } from '~/components/graphs/LineGraph'
import { getColorByIndex } from '~/utils/colors'
import type ValuationModel from '~/models/Company/ValuationModel'
import { useCallback, useMemo } from 'react'

type ValuationGraphData = {
  currentDate: number
  labelView: string[]
  datasets: IDataset[]
  currentDateIndex: number
}

export const basicRounding = (value: number, decimals = 2) => {
  const x = Math.pow(10, decimals)
  return Math.round(value * x) / x
}

const getCompanyValuationGraphData = (valuations: ValuationModel[]): ValuationGraphData => {
  const currentDate = DateTime.now().toMillis()

  if (!valuations.length) {
    return { labelView: [], datasets: [], currentDate, currentDateIndex: 0 }
  }

  let labels =
    uniq(
      flatten(
        valuations.map((valuation) =>
          valuation.periods.map((period) => DateTime.fromISO(period.validFrom).toMillis())
        ) || []
      )
    ) || []
  labels.push(currentDate)
  labels = labels.sort((a, b) => a - b)
  const currentDateIndex = labels.indexOf(currentDate)

  const labelView: string[] = labels.map((label) => DateTime.fromMillis(label).toISO()) as string[]

  const datasets: IDataset[] = valuations.reduce((acc, companyValuation, index) => {
    const graphData: (number | null)[] = []
    labels.forEach((label) => {
      const period = companyValuation.periods.find((period) => {
        return DateTime.fromISO(period.validFrom).toMillis() === label
      })
      if (period) {
        graphData.push(period.valuation)
      } else {
        graphData.push(null)
      }
    })
    graphData[currentDateIndex] =
      findLast(graphData.slice(0, currentDateIndex + 1), (value) => value !== null) || null

    const graphColor = getColorByIndex(index)
    acc.push({
      label: companyValuation.name,
      data: graphData,
      color: graphColor,
      borderStyle: 'dashed',
      stepped: 'before',
      segment: {
        borderDash: (ctx: { p1DataIndex: number }) => {
          return (DateTime.fromMillis(labels[ctx.p1DataIndex]).toISODate() ?? '') >
            (DateTime.now().toISODate() ?? '')
            ? [6, 5]
            : []
        }
      }
    })
    return acc
  }, [] as IDataset[])

  return {
    currentDate,
    labelView,
    datasets,
    currentDateIndex
  }
}

export const useValuation = () => {
  const { valuations, sharesSorted } = useCompanyStore()

  const numberOfShares = sharesSorted?.length ? sharesSorted[0].shares : 0

  const companyValuationGraphData: ValuationGraphData = useMemo(
    () => getCompanyValuationGraphData(valuations),
    [valuations]
  )

  const primaryValuation: ValuationModel | undefined = useMemo(
    () => valuations.find((valuation) => valuation.isPrimary),
    [valuations]
  )

  const publicValuation: ValuationModel | undefined = useMemo(
    () => valuations.find((valuation) => valuation.isPublic),
    [valuations]
  )

  const currentCompanyValuation: number = useMemo(
    () => publicValuation?.getValuation() || 0,
    [publicValuation]
  )

  const getShares = useCallback(
    (date?: DateTime): number => {
      if (!sharesSorted.length) {
        return 0
      }
      const toDate = (date ?? DateTime.now()).startOf('day')
      const share = sharesSorted.find(
        (s) =>
          !s.validFrom ||
          (s.validFromDateTime as DateTime).startOf('day').toMillis() <= toDate.toMillis()
      )
      return share?.shares ?? 0
    },
    [sharesSorted]
  )

  const getValuationPricePerAsset = useCallback(
    (companyValuation: ValuationModel, date?: DateTime, assetRatio = 1): number => {
      const valuation = companyValuation.getValuation(date) || 0

      return valuation > 0 ? valuation / getShares(date) / assetRatio : 0
    },
    [getShares]
  )

  const getPricePerAsset = useCallback(
    (
      valuationType: ValuationType,
      date?: DateTime,
      assetRatio = 1,
      companyValuationId?: number
    ): number => {
      const companyValuation = companyValuationId
        ? valuations.find((valuation) => valuation.id === companyValuationId)
        : valuationType === 'public'
        ? publicValuation
        : primaryValuation

      return companyValuation ? getValuationPricePerAsset(companyValuation, date, assetRatio) : 0
    },
    [getValuationPricePerAsset, primaryValuation, publicValuation, valuations]
  )

  const getPricePerAssetPublic = useCallback(
    (date?: DateTime, assetRatio = 1, companyValuationId?: number): number => {
      return getPricePerAsset('public', date, assetRatio, companyValuationId)
    },
    [getPricePerAsset]
  )

  const getPricePerAssetPrimary = useCallback(
    (date?: DateTime, assetRatio = 1): number => {
      return getPricePerAsset('primary', date, assetRatio)
    },
    [getPricePerAsset]
  )

  const assetsCalcPublic = useCallback(
    (assetCardinality = 1, assetRatio = 1, date?: DateTime) => {
      const rawPricePerAsset = getPricePerAsset('public', undefined, assetRatio)
      const percentage: number = basicRounding(
        (assetCardinality / assetRatio / getShares(date)) * 100,
        3
      )
      const totalValue: number = basicRounding(
        assetCardinality === 0 ? 0 : rawPricePerAsset * assetCardinality
      )
      const pricePerAsset: number = basicRounding(rawPricePerAsset, 6)

      return {
        percentage,
        totalValue,
        pricePerAsset
      }
    },
    [getPricePerAsset, getShares]
  )

  const assetCalcAmount = (
    assetCardinality: number,
    assetRatio: number,
    pricePerAsset: number,
    date?: DateTime
  ) => {
    const percentage: number = basicRounding(
      (assetCardinality / assetRatio / getShares(date)) * 100,
      3
    )
    const totalValue: number = basicRounding(pricePerAsset * assetCardinality)

    return {
      amount: assetCardinality,
      percentage,
      totalValue
    }
  }

  const assetCalcTotalValue = (
    totalValue: number,
    assetRatio: number,
    pricePerAsset: number,
    date?: DateTime
  ) => {
    // we have to round because of javascript number inaccuracy in decimal places (e.g. 1000 * 0.20669)
    // assuming max total value in tens of millions, 8 places should work
    // same for assetCalcPercentage
    const amount: number = pricePerAsset ? basicRounding(totalValue / pricePerAsset, 8) : 0
    const percentage: number = basicRounding((amount / assetRatio / getShares(date)) * 100, 3)

    return {
      amount,
      totalValue,
      percentage
    }
  }

  const assetCalcPercentage = (
    percentage: number,
    assetRatio: number,
    pricePerAsset: number,
    date?: DateTime
  ) => {
    const amount: number = basicRounding((percentage / 100) * assetRatio * getShares(date), 8)
    const totalValue: number = basicRounding(pricePerAsset * amount)

    return {
      amount,
      totalValue,
      percentage
    }
  }

  const assetCalcPricePerAsset = useCallback(
    (totalValue: number, assetRatio: number, date?: DateTime) =>
      basicRounding(totalValue / assetRatio / getShares(date), 6),
    [getShares]
  )

  const assetCalcAwardPricePerAsset = useCallback(
    (awardValuation?: AwardValuation, date?: DateTime, assetRatio = 1) => {
      let result
      if (awardValuation?.type === 'fixed') {
        result = awardValuation.value
      } else {
        result = getPricePerAsset(
          awardValuation?.type === 'dynamic' && awardValuation?.valuationType === 'public'
            ? 'public'
            : 'primary',
          date,
          assetRatio
        )

        if (awardValuation?.discount) {
          result = (result * (100 - awardValuation.discount)) / 100
        }

        result = basicRounding(result, 6)
      }
      return result
    },
    [getPricePerAsset]
  )

  return {
    numberOfShares,
    valuations,
    currentCompanyValuation,
    primaryValuation,
    publicValuation,
    companyValuationGraphData,
    assetCalcAmount,
    assetCalcTotalValue,
    assetCalcPercentage,
    assetCalcPricePerAsset,
    assetCalcAwardPricePerAsset,
    assetsCalcPublic,
    getPricePerAssetPublic,
    getPricePerAssetPrimary,
    getValuationPricePerAsset
  }
}

export type Valuation = ReturnType<typeof useValuation>
